import { filterChildrenBtStyledComponentId } from "@utils/filterChildrenByStyledComponentId";
import { Children } from "react";
import styled from "styled-components";
import { A11y, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";

export const Slide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Slider = styled(
  ({
    children,
    ...props
  }: { children: any } & Pick<
    SwiperProps,
    | "spaceBetween"
    | "slidesPerGroup"
    | "slidesPerView"
    | "loop"
    | "autoplay"
    | "navigation"
    | "onSlideChange"
  >) => {
    const _children = filterChildrenBtStyledComponentId(
      children,
      [Slide],
      "Slider component only renders children of type Slide"
    );



    return (
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        pagination={{ clickable: true }}
        {...props}>
        {Children.map(_children, (child, idx) => {
          return <SwiperSlide key={idx}>{child}</SwiperSlide>;
        })}
      </Swiper>
    );
  }
)`
  cursor: grab;
  .swiper-slide {
    height: auto;
  }

  .swiper-pagination-bullet {
    background: transparent;
    border: 2px solid #eee;
    opacity: 0.9;
    transition: background 0.35s;
  }

  .swiper-pagination-bullet-active {
    background: #eee;
    transition: background 0.35s;
  }
`;
