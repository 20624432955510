import { Children } from "react";
import { StyledComponent } from "styled-components";

export function filterChildrenBtStyledComponentId(
  children: React.ReactElement | React.ReactElement[],
  allowedComponentId: StyledComponent<any, any>[],
  info?: string
): React.ReactElement<any, string | React.JSXElementConstructor<any>>[] {
  const _children = Children.map(children, (child) => {
    if (!child) return false;

    const _childUID = (child.type as any).styledComponentId as string;
    if (
      _childUID &&
      allowedComponentId.some((allowed) =>
        _childUID.includes((allowed as any).styledComponentId)
      )
    )
      return child;
    else return false;
  });

  if (info && _children.some((child) => !child)) console.info("⚠", info, "⚠");

  return _children.filter(Boolean);
}
