import { Button as MaterialButton } from "@material-ui/core";
import { FC } from "react";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";

interface IButtonProps {
  buttonType?: "primary" | "secondary" | "tertiary" | "error" | "noOutline";
  className?: string;
  disabled?: boolean;
  href?: string;
  isLoading?: boolean;
  onClick?: (event: any) => void;
  width?: string;
  targetBlank?: boolean;
}

const Button: FC<IButtonProps> = ({
  buttonType = "primary",
  children,
  disabled,
  href,
  isLoading,
  targetBlank,
  ...props
}) => {
  const buttonClasses = classNames(
    {
      "border border-solid !border-[#BDBDBD] !bg-navy-blue hover:!bg-white !text-white hover:!text-navy-blue !hover:!border-navy-blue":
        buttonType === "primary" && !disabled,
      "border border-solid !border-[#BDBDBD] hover:!bg-navy-blue !bg-white hover:!text-white !text-navy-blue":
        buttonType === "secondary",
      "!bg-gray-blue text-white": buttonType === "tertiary",
      "!bg-error text-white": buttonType === "error",
      "text-black h-30 !underline bg-transparent": buttonType === "noOutline",
      "!bg-gray border !text-dark-gray !font-benton-bold !pointer-events-none !cursor-not-allowed border-dark-gray":
        disabled,
    },
    "!normal-case !align-middle w-full h-[50px] !pb-[4px] rounded-sm cursor-pointer not-italic font-medium !text-15 leading-none text-center",
    props.className
  );

  const button = (
    <MaterialButton
      {...props}
      disabled={disabled}
      className={buttonClasses}
      style={{
        width: props.width ?? "100%",
      }}
      endIcon={
        isLoading ? (
          <Image
            unoptimized={true}
            src={"/images/loading.gif"}
            alt={"loading"}
            width={16}
            height={16}
          />
        ) : null
      }>
      {children}
    </MaterialButton>
  );

  if (href) {
    if (targetBlank)
      return (
        <a href={href} target="_blank" rel="noreferrer">
          {button}
        </a>
      );
    return <Link href={href}>{button}</Link>;
  } else {
    return button;
  }
};

export default Button;
