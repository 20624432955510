import { Box, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import Image from "next/image";
import { CardMedia } from "@material-ui/core";
import { EViewSize } from "@enums";
import { getCloudinaryImage } from "@utils/wallet/numberFormet.util";
import { LAZY_LOAD_IMAGE_BLUR } from "@utils/lazyLoadImageBlur";
import { IVideoFields } from "@metaverse/types";

interface videoData {
  video?: any;
  data: {
    title?: string;
    text?: string;
    textCaption?: string;
    description?: string;
    page?: string;
  };
}

interface IVideoData extends IVideoFields {
  description?: string;
}

export const VideoSection = styled((props: videoData) => {
  if (!props) return null;

  const { title, text, textCaption, video, description } =
    props?.data as IVideoData;

  const getVideoUrl = video?.fields?.file?.url ?? video;

  return (
    <Box {...props}>
      <Grid container className="videobackground ">
        <Grid item lg={12} sm={12} sx={{ width: "100%" }}>
          {props?.data?.page == "artist" ? (
            <Box>
              {title && (
                <Typography
                  variant="h2"
                  className="heading-md font-mercury text-center"
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}></Typography>
              )}
              {text && (
                <Typography
                  className="text-16 text-center leading-[19px] tracking-tighter"
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}></Typography>
              )}
              {textCaption && (
                <Typography
                  className="text-16 text-center font-normal italic leading-[19px] tracking-tighter"
                  dangerouslySetInnerHTML={{
                    __html: textCaption,
                  }}></Typography>
              )}
            </Box>
          ) : props?.data?.page == "home" ? (
            <Box>
              {title && (
                <Typography
                  variant="h3"
                  className="heading-sm text-center"
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}></Typography>
              )}
            </Box>
          ) : (
            <Box>
              {title && (
                <Typography
                  variant="h2"
                  className="heading-md font-mercury mb-40 mt-20 text-balance text-center text-[30px] font-normal tracking-tighter text-black lg:mt-0 lg:text-[36px] lg:leading-[43px]"
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}></Typography>
              )}
              {description && (
                <Typography
                  className="text-center text-black"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}></Typography>
              )}
            </Box>
          )}
        </Grid>
        {video && (
          <Grid item lg={12} sm={12}>
            {video.fields?.file?.contentType.includes("image") ? (
              <Box className="imageatVideo mx-auto max-h-[60%] max-w-[65%]">
                <Image
                  src={getCloudinaryImage(
                    `https:${video.fields?.file?.url}?w=1920&q=25`
                  )}
                  alt={video.fields.title}
                  layout="responsive"
                  objectFit="cover"
                  width="100%"
                  height="100%"
                  blurDataURL={LAZY_LOAD_IMAGE_BLUR}
                  placeholder="blur"
                />
              </Box>
            ) : (
              <Box className="imageatVideo mx-auto max-h-[60%] max-w-[65%]">
                <CardMedia
                  src={getCloudinaryImage(`https:${getVideoUrl}`)}
                  component="video"
                  className="videoSize"
                  title="animated-url"
                  controls
                  muted
                />
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
})`
  @media screen and (max-width: 1400px) {
    .imageatVideo {
      max-width: 80%;
      max-height: 80%;
    }
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    .imageatVideo {
      max-width: 85%;
      max-height: 85%;
    }
  }
`;
