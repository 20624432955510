import React from "react";
import { Section } from "@secondary/Section";
import styled from "styled-components";
import Container from "@mui/material/Container";
import Image from "next/image";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Button from "@metaverse/components/ui/atoms/Button";
import { getCloudinaryImage } from "@utils/wallet/numberFormet.util";
import { IFeaturedArtistFields } from "@metaverse/types";
import Link from "next/link";

interface IFeaturedArtistSectionProps {
  item: IFeaturedArtistFields;
}

export const FeaturedArtistSection = styled(
  ({ item, ...props }: IFeaturedArtistSectionProps) => {
    if (!item) return null;

    const { title, image, description, button_redirecting_url } = item;

    return (
      <Section {...props}>
        <Box className="featured-artist-block">
          <Container>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-32">
              <div className="order-flex flex w-full items-center">
                <Box>
                  <h3 className="heading-sm">Artist Spotlight</h3>
                  {title && (
                    <Typography
                      component="div"
                      variant="h1"
                      className="text-[15px] font-bold leading-[22px] tracking-tighter text-black"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  )}
                  {description && (
                    <Box className="[&>p]:pb-15 mt-30 mb-15">
                      <Typography
                        className="text-light-black text-[15px] leading-[22px] tracking-tighter"
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      />
                    </Box>
                  )}
                  {button_redirecting_url && (
                    <Link href={button_redirecting_url} passHref>
                      <Button className="max-w-[224px]">Explore Artist</Button>
                    </Link>
                  )}
                </Box>
              </div>
              {image && (
                <div className="w-full">
                  {image.fields?.file?.contentType.includes("image") ? (
                    <Image
                      src={getCloudinaryImage(
                        `https:${image.fields?.file?.url}?w=1920&q=25`
                      )}
                      alt={image.fields.title}
                      width="100%"
                      height="80%"
                      layout="responsive"
                    />
                  ) : (
                    <CardMedia
                      image={getCloudinaryImage(
                        `https:${image.fields?.file?.url}`
                      )}
                      component="video"
                      className="videoSize"
                      title="animated-url"
                      controls
                      muted
                    />
                  )}
                </div>
              )}
            </div>
          </Container>
        </Box>
      </Section>
    );
  }
)`
  @media screen and (max-width: 767px) {
    .order-flex {
      order: 2;
    }
  }
`;
