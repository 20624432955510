import styled from "styled-components";

export const Section = styled.div<{ $bgColor?: string }>`
  position: relative;
  max-width: 100%;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.$bgColor ?? "transparent"};
  }
`;
