import { useDeviceResponsive } from "@hooks";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Section } from "@secondary/Section";
import { Slide, Slider } from "@secondary/Slider";
import { palette } from "@styles/theme";
import Image from "next/image";
import React from "react";
import styled from "styled-components";
import Link from "next/link";
import { getCloudinaryImage } from "@utils/wallet/numberFormet.util";
import { LAZY_LOAD_IMAGE_BLUR } from "@utils/lazyLoadImageBlur";

export const UpcomingSales = styled((props) => {
  const { isMobile, isTablet } = useDeviceResponsive();

  return (
    <Section {...props}>
      <div className="home_theme_background">
        <Container>
          <Section>
            <Box className="section-pd-62">
              <h3 className="home_theme_title">{props?.item?.title}</h3>
              <div style={{ position: "relative" }} className="sliderBtmDots">
                <Slider
                  autoplay
                  slidesPerView={isMobile ? 1 : isTablet ? 2 : 3}
                  slidesPerGroup={isMobile ? 1 : isTablet ? 2 : 3}
                  spaceBetween={50}>
                  {props?.item?.saveData?.map((data: any, key: any) => {
                    return (
                      //slider image change fix
                      <Slide key={key}>
                        <Box className="card-list-block UPSNavyblue">
                          <Box className="imageFile">
                            <Box className="upcomingsalesTopImage">
                              {data?.sale_info}
                            </Box>
                            <Image
                              src={getCloudinaryImage(`https:${data?.url}`)}
                              height="100%"
                              width="100%"
                              layout="responsive"
                              objectFit="fill"
                              className="upcomingSalesImage"
                              blurDataURL={LAZY_LOAD_IMAGE_BLUR}
                              placeholder="blur"
                              alt=""
                            />
                          </Box>
                          <Box className="details boxTextData mt-2 p-7">
                            {data?.redirectionUrl ? (
                              <Link
                                href={data?.redirectionUrl}
                                passHref
                                className="titleNavbar">
                                {data?.title}
                              </Link>
                            ) : (
                              <Box className="titleNavbar">{data?.title}</Box>
                            )}
                            <Box className="artist my-2">{data?.sub_title}</Box>
                          </Box>
                        </Box>
                      </Slide>
                    );
                  })}
                </Slider>
              </div>
            </Box>
          </Section>
        </Container>
      </div>
    </Section>
  );
})`
  .upcomingsalesTopImage {
    background: ${palette.navyBlue};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-self: center;
    padding: 8px 16px;
    position: absolute;
    top: 16px;
    left: 10px;
    z-index: 999;
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  .upcomingDesc {
    font-family: BentonSans;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }

  .UPSNavyblue {
    background-color: ${palette.navyBlue};
    width: 100%;
  }

  .boxTextData {
    overflow: hidden;
  }

  .titleNavbar {
    font-family: "Mercury Display";
    font-style: normal;
    font-weight: 325;
    font-size: 36px;
    line-height: 75px;
    color: #000000;
  }

  @media screen and (max-width: 1320px) {
    .upcomingsalesTopImage {
      right: 10px;
    }
  }

  @media screen and (max-width: 576px) {
    .upcomingsalesTopImage {
      right: auto;
    }
  }

  @media screen and (max-width: 398px) {
    .upcomingsalesTopImage {
      right: 10px;
    }
  }
`;
