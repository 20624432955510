import { useCallback } from "react";
import { Section } from "@secondary/Section";
import { FeaturedArtistSection } from "@sections/dynamic/FeaturedArtist";
import { HighlightsSection } from "@sections/dynamic/Highlights";
import { UpcomingSales } from "@sections/dynamic/UpcomingSales";
import { PageHeader } from "@sections/dynamic/PageHeader";
import { getAuthors, getPageHeaders } from "@utils";
import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import styled from "styled-components";
import { useProfile } from "@hooks";
import { Box } from "@mui/material";
import { VideoSection } from "@sections/dynamic/VideoSection";
import Head from "next/head";

const DynamicHome = styled((props) => {
  const { sections } = props;
  const { profile } = useProfile();

  const renderContent = useCallback(
    (contentType: { sys: { id: string } }, item: any) => {
      switch (contentType?.sys?.id) {
        case "bannerPage":
          return (
            <Box>
              <PageHeader item={item} />
            </Box>
          );
        case "upcommingSales":
          return (
            <Box className="section-pd-62">
              <UpcomingSales item={item} />
            </Box>
          );
        case "featuredArtist":
          return (
            <Box className="section-pd-62">
              <FeaturedArtistSection item={item} />
            </Box>
          );
        case "video":
          return (
            <Box className="section-pd-62">
              <VideoSection data={item} />
            </Box>
          );
        case "highlighted_Nfts":
          return (
            <Box>
              <HighlightsSection item={item} profile={profile} />
            </Box>
          );
        default:
          return null;
      }
    },
    []
  );

  return (
    <Section {...props}>
      <Head>
        <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
        <title>{"Sotheby's Metaverse"}</title>
        <meta
          name="description"
          content={
            "Sotheby's is the premier destination for curated digital artworks and collectibles. Discover top Artists. Browse upcoming sales. Buy and sell rare digital works."
          }
        />
      </Head>
      <div className="homePage">
        <Section className="">
          {sections.map((item: any, index: number) => (
            <Box key={index}>
              {renderContent(item.sys.contentType, item.fields)}
            </Box>
          ))}
        </Section>
      </div>
    </Section>
  );
})``;

export async function getServerSideProps(
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<any>> {
  await getPageHeaders(context);
  await getAuthors(context);

  return { props: {} };
}

export default DynamicHome;
