import { Section } from "@secondary/Section";
import { Slide, Slider } from "@secondary/Slider";
import styled from "styled-components";
import Image from "next/image";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@metaverse/components/ui/atoms/Button";
import Link from "next/link";
import { getCloudinaryImage } from "@utils/wallet/numberFormet.util";
import { LAZY_LOAD_IMAGE_BLUR } from "@utils/lazyLoadImageBlur";
import { IBannerFields, IBannerPageFields } from "@metaverse/types";

interface IPageHeaderProps {
  item: IBannerPageFields;
}

export const PageHeader = styled(({ item, ...props }: IPageHeaderProps) => {
  if (!item) return null;

  return (
    <Section {...props}>
      <div className="homePageSlider sliderBtmDots">
        <Slider loop autoplay>
          {item?.lot?.map((item, idx) => {
            const {
              title,
              image,
              description,
              buttonTitle,
              button_redirecting_url,
            } = item?.fields as IBannerFields;

            return (
              <Slide key={idx}>
                <Box sx={{ flexGrow: 1 }} className="sliderPageHeader">
                  <Grid container className="bg-light-white items-center">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      className="grid-100 order-flex">
                      <Box className="relative flex h-full w-full flex-col items-start justify-center gap-32 p-[20px_15px] xl:p-[32px_100px]">
                        {title && (
                          <Box className="flex w-full flex-col items-start justify-center text-center xl:text-left">
                            <Typography
                              variant="h1"
                              className="heading-lg w-full text-balance text-center text-[30px] lg:text-[40px] xl:text-left xl:text-[48px]"
                              dangerouslySetInnerHTML={{
                                __html: title,
                              }}></Typography>
                          </Box>
                        )}
                        {description && (
                          <Box className="w-full text-center xl:text-left">
                            <Typography
                              variant="body1"
                              className="home_theme_desc w-full text-balance text-center !leading-normal xl:text-left"
                              dangerouslySetInnerHTML={{
                                __html: description,
                              }}></Typography>
                          </Box>
                        )}
                        {button_redirecting_url && (
                          <Link href={button_redirecting_url} passHref>
                            <Button className="mx-auto max-w-[224px] xl:mx-0">
                              {buttonTitle}
                            </Button>
                          </Link>
                        )}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      className="grid-100">
                      {image && (
                        <Box sx={{ postion: "relative" }}>
                          {image.fields?.file?.contentType.includes("image") ? (
                            <Image
                              src={getCloudinaryImage(
                                `https:${image.fields?.file?.url}?w=1920&q=25`
                              )}
                              alt={image.fields.title}
                              width={640}
                              height={342}
                              layout="responsive"
                              objectFit="cover"
                              blurDataURL={LAZY_LOAD_IMAGE_BLUR}
                              placeholder="blur"
                              loading={idx !== 0 ? "lazy" : undefined}
                              priority={idx === 0}
                            />
                          ) : (
                            <CardMedia
                              image={getCloudinaryImage(
                                `https:${image.fields?.file?.url}`
                              )}
                              component="video"
                              className="videoSize"
                              title="animated-url"
                              autoPlay
                              muted
                            />
                          )}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Slide>
            );
          })}
        </Slider>
      </div>
    </Section>
  );
})`
  @media screen and (max-width: 1200px) {
    .sliderPageHeader {
      height: auto;
    }

    .grid-100 {
      width: 100% !important;
      max-width: 100% !important;
      flex-basis: 100%;
    }

    .order-flex {
      order: 2;
    }

    .artistBanner {
      padding: 16px 30px;
      justify-content: center !important;
      align-items: center !important;
    }

    .artistBanner p {
      text-align: center;
    }
  }

  .homePageSlider .swiper {
    padding-bottom: 32px;

    @media (max-width: 991px) {
      padding-bottom: 10px;
    }
  }

  .homePageSlider .swiper-pagination {
    bottom: 0px;
  }
`;
